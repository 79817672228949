export default function (account) {
   const {
     account_id,
     currency_type,
     status,
     type: accountType,
     user_id,
     wallet_id
   } = account
  this.accountId = account_id
  this.currencyType = currency_type
  this.status = status
  this.accountType = accountType
  this.userId = user_id
  this.walletId = wallet_id
}
