import { BigInteger } from '../modules/BigInteger'

export default {
  state: {
    B: '',
    s: '',
    digest: ''
  },
  getters: {
    getAuthForm2Client (state) {
      return state
    }
  },
  mutations: {
    updateAuthForm2Client (state, dataForUpdate) {
      state.B = new BigInteger(dataForUpdate.b, 10)
      state.s = dataForUpdate.s
      state.digest = dataForUpdate.digest
    }
  }
}
