import { SignService } from '@/Services/SignService'
import { actionSignature } from '@/Methods/ActionSignature'
import { TransactionSignatures } from '@/modules/Signatures'
import { getDeepSortedPropsObj } from '@/utils/common'

export default class CommissionLockRequest {
  constructor ({ amount, currency_type = 'lion' }) {
    this.amount = amount
    this.currency_type = currency_type
  }

  getSignedRequest (key, userId) {
    const data = getDeepSortedPropsObj(this)
    let signService = new SignService(key, userId)
    let actionId = actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.lockCommissionRequest)
    return signService.createSignedRequest(actionId, data)
  }
}
