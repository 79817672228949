export const OperationTypes = {
  lock_amount: 'LOCK_AMOUNT',
  lock_commission: 'LOCK_COMMISSION',
  transfer_amount_to_user: 'TRANSFER_AMOUNT_TO_USER',
  transfer_commission_to_system: 'TRANSFER_COMMISSION_TO_SYSTEM',
  unlock_amount_to_user: 'UNLOCK_AMOUNT_TO_USER',
  unlock_commission_to_user: 'UNLOCK_COMMISSION_TO_USER'
}

export function isCommissionOperation (opType) {
  return opType === OperationTypes.unlock_commission_to_user ||
    opType === OperationTypes.lock_commission ||
    opType === OperationTypes.transfer_commission_to_system
}
