import { OFFER_TYPE_SOCKET_NAMES } from '@/constants/requests'
import { OFFER_TYPE_NAMES } from '@/constants/requests'

const {
  INPUT,
  OUTPUT
} = OFFER_TYPE_NAMES

export default function (data) {
  const { offer_records: { offer_records }, type } = data
  this.request = {}
  const isInputType = type === OFFER_TYPE_SOCKET_NAMES.In
  this.request.input_output_type = isInputType ? INPUT : OUTPUT
  this.request.in = offer_records.find(i => i.input_output_type === INPUT)
  this.request.out = offer_records.find(i => i.input_output_type === OUTPUT)
  this.operation_id =this.request.in.operation_id
}
