export default function (data = {}) {
  const {
    currency = '',
    min_amount = '',
    step = '',
    banknotes = []
  } = data
  return {
    currency,
    min_amount,
    step,
    banknotes
  }
}
