export default class RequisitesModel {
  constructor (requisitesType) {
    this.type = requisitesType
    this.fields = REQUISITES_BY_TYPE[requisitesType].fields
    this.component = REQUISITES_BY_TYPE[requisitesType].component
  }

}

export const REQUISITES_TYPES = {
  CARD: 'CardAmount',
  BANK_TRANSFER: 'AccountAmount',
  CASH: 'Cash'
}

export const REQUISITES_TYPES_FOR_REQUEST = {
  [REQUISITES_TYPES.BANK_TRANSFER]: 'account_amount',
  [REQUISITES_TYPES.CARD]: 'card_amount',
  [REQUISITES_TYPES.CASH]: 'cash'
}

export const REQUISITES = [
  {
    label: 'p2p_requisites_card_title',
    value: REQUISITES_TYPES.CARD
  },
  {
    label: 'p2p_requisites_account_title',
    value: REQUISITES_TYPES.BANK_TRANSFER
  },
  {
    label: 'p2p_requisites_cash_title',
    value: REQUISITES_TYPES.CASH
  },
]

export const REQUISITES_BY_TYPE = {
  [REQUISITES_TYPES.CARD]: {
    component: 'CardRequisites',
    fields: [
      {
        label: 'p2p_requisites_card_bank',
        propName: 'bank'
      },
      {
        label: 'p2p_requisites_card_number',
        propName: 'card_number'
      },
      {
        label: 'p2p_requisites_desc',
        propName: 'description'
      },
    ]
  },
  [REQUISITES_TYPES.BANK_TRANSFER]: {
    component: 'BankTransferRequisites',
    fields: [
      {
        label: 'p2p_requisites_card_bank',
        propName: 'bank'
      },
      {
        label: 'p2p_requisites_bank_bik',
        propName: 'bik'
      },
      {
        label: 'p2p_requisites_bank_korr',
        propName: 'korr_account_number'
      },
      {
        label: 'p2p_requisites_bank_acc',
        propName: 'account_number'
      },
      {
        label: 'p2p_requisites_bank_fio',
        propName: 'fio'
      },
      {
        label: 'p2p_requisites_desc',
        propName: 'description'
      }
    ]
  },
  [REQUISITES_TYPES.CASH]: {
    component: 'CashRequisites',
    fields: [
      {
        label: 'p2p_requisites_cash_address',
        propName: 'address'
      },
      {
        label: 'p2p_requisites_desc',
        propName: 'description'
      }
    ]
  },
}
