import { SignService } from "@/Services/SignService";
import { actionSignature } from "@/Methods/ActionSignature";
import {
  AutoexchangeSignatures,
} from "@/modules/Signatures";
import { getDeepSortedPropsObj } from '@/utils/common'

export class RequestOfferModel {
  constructor({
    userId,
    walletId,
    accountId,
    ownedType,
    ownedAmount,
    ownedCurrency,
    ownedAsset,
    ownedFiatTypes,
    wantedAmount,
    wantedCurrency,
    wantedType,
    wantedAsset,
    wantedFiatTypes,
    exchangeRate,
    minMatchAmount,
    country,
    city,
    requisites_hash,
    response_offer_op_id,
    banknotes
  }) {
    this.exchange_rate = exchangeRate || '0'
    this.banknotes = banknotes
    this.country = country
    this.city = city
    this.min_match_amount = minMatchAmount || '0'
    this.owned_value = {
      amount: ownedAmount || null,
      asset: ownedAsset || { name: '' },
      currency: ownedCurrency || null,
      fiat_types: ownedFiatTypes || [],
      type: ownedType || null,
    };
    this.requisites_hash = requisites_hash || null
    this.response_offer_op_id = response_offer_op_id || ''
    this.user_account_info = {
      account_id: accountId,
      user_id: userId,
      wallet_id: walletId,
    };
    this.wanted_value = {
      amount: wantedAmount || null,
      asset: wantedAsset || { name: '' },
      currency: wantedCurrency || null,
      fiat_types: wantedFiatTypes || [],
      type: wantedType || null,
    };
    if (!this.wanted_value.amount) delete this.wanted_value.amount
    if (!this.wanted_value.currency) delete this.wanted_value.currency
    if (!this.owned_value.amount) delete this.owned_value.amount
    if (!this.owned_value.currency) delete this.owned_value.currency
  }

  getSignedRequest(key, userId) {
    // const sortedData = {}
    // // Для подписи используются отсортированные свойства в объекте
    // Object.keys(this).sort((a, b) => a.localeCompare(b)).forEach(prop => {
    //   sortedData[prop] = this[prop]
    // })
    const data = getDeepSortedPropsObj(this)
    let signService = new SignService(key, userId)
    let actionId = actionSignature(
      AutoexchangeSignatures.channel,
      AutoexchangeSignatures.chaincode,
      AutoexchangeSignatures.action.addP2POffer
    )
    if (!Object.keys(data.wanted_value.asset).length) delete data.wanted_value.asset
    if (!Object.keys(data.owned_value.asset).length) delete data.owned_value.asset
    return signService.createSignedRequest(actionId, data)
  }
}
