import { createDateOfTimeStamp, createTimeOfTimeStamp, createTimeWithSecondsOfTimeStamp } from '@/Methods/GlobalMethods'
import { CURRENCY_SHORT_CODES } from '@/constants/auto-exchange'
import Decimal from 'decimal.js'
import { TRANSACTION_NAME_ALIASES } from '@/constants/transactions'
import { isCommissionOperation, OperationTypes } from '@/models/OperationTypes'
import { getSystemUserCode } from '@/models/SystemUsers'
import { ProcessTypes } from '@/models/ProcessTypes'
import { TRANSACTION_TYPES } from '@/constants/common'

export function getStatementUserDescription (payment) {
  const recipientTypeCode = `${payment.recipientId}/${payment.processType}/${payment.type}`
  if (TRANSACTION_NAME_ALIASES[recipientTypeCode]) return window.$t(TRANSACTION_NAME_ALIASES[recipientTypeCode])
  const isProdEnv = ['cryptoenter.com', 'cryptoenter.ru'].includes(window.location.host)
  if (isCommissionOperation(payment.operationType)) {
    return payment.currency === 'lion' ? window.$t(getSystemUserCode(payment.recipientId)) : window.$t('statement_netFee')
  } else if (payment.processType === ProcessTypes.withdrawal_funds) {
    return window.$t(isProdEnv ? 'statement_withdrawalToMN' : 'statement_withdrawalToTN', { curr: payment.currencyForLocale })
  } else if (payment.processType === ProcessTypes.deposit_funds) {
    return window.$t(isProdEnv ? 'statement_depositFromMN' : 'statement_depositFromTN', { curr: payment.currencyForLocale })
  } else if (payment.processType === ProcessTypes.autoexchange) {
    return window.$t('ae_autoexchange')
  } else {
    return payment.recipientId
  }
}

const {
  autoexchange,
  exchange_with_both_commission,
  transfer_receiver_commission,
  transfer_sender_commission,
  withdrawal_funds,
  deposit_funds,
  transfer_fiat
} = ProcessTypes

const {
  lock_amount,
  lock_commission,
  unlock_amount_to_user,
  unlock_commission_to_user,
  transfer_amount_to_user,
  transfer_commission_to_system
} = OperationTypes

const { output, input } = TRANSACTION_TYPES

const isProdEnv = ['cryptoenter.com', 'cryptoenter.ru'].includes(window.location.host)

export const statementMapLocales = {
  [`${autoexchange}/${lock_amount}/${output}`]: () => window.$t('statement2_autoexchange_cr_amount'),
  [`${autoexchange}/${lock_commission}/${output}`]: () => window.$t('statement2_autoexchange_cr_comm'),
  [`${autoexchange}/${transfer_amount_to_user}/${input}`]: () => window.$t('statement2_autoexchange_res_amount'),
  [`${autoexchange}/${unlock_commission_to_user}/${input}`]: () => window.$t('statement2_autoexchange_comm_ret'),
  [`${autoexchange}/${unlock_amount_to_user}/${input}`]: () => window.$t('statement2_autoexchange_amount_ret'),
  [`${autoexchange}/${transfer_commission_to_system}/${output}`]: (payment) => window.$t(payment.currency === 'lion' ? getSystemUserCode(payment.recipientId) : 'statement_netFee'),

  [`${exchange_with_both_commission}/${lock_amount}/${output}`]: (payment) => window.$t('statement2_exchange_cr_amount', { userId: payment.recipientId }),
  [`${exchange_with_both_commission}/${lock_commission}/${output}`]: () => window.$t('statement2_exchange_cr_comm'),
  [`${exchange_with_both_commission}/${unlock_amount_to_user}/${input}`]: (payment) => window.$t('statement2_exchange_amount_ret', { userId: payment.recipientId }),
  [`${exchange_with_both_commission}/${unlock_commission_to_user}/${input}`]: () => window.$t('statement2_exchange_comm_ret'),
  [`${exchange_with_both_commission}/${transfer_amount_to_user}/${input}`]: (payment) => window.$t('statement2_exchange_input_amount', { userId: payment.recipientId }),

  [`${transfer_sender_commission}/${lock_amount}/${output}`]: (payment) => window.$t('statement2_transfer_cr_amount', { userId: payment.recipientId }),
  [`${transfer_receiver_commission}/${lock_amount}/${output}`]: (payment) => window.$t('statement2_transfer_cr_amount', { userId: payment.recipientId }),
  [`${transfer_sender_commission}/${lock_commission}/${output}`]: () => window.$t('statement2_transfer_comm'),
  [`${transfer_receiver_commission}/${lock_commission}/${output}`]: () => window.$t('statement2_transfer_comm'),
  [`${transfer_sender_commission}/${transfer_amount_to_user}/${input}`]: (payment) => window.$t('statement2_transfer_input', { userId: payment.recipientId }),
  [`${transfer_receiver_commission}/${transfer_amount_to_user}/${input}`]: (payment) => window.$t('statement2_transfer_input', { userId: payment.recipientId }),

  [`${withdrawal_funds}/${lock_amount}/${output}`]: () => window.$t(isProdEnv ? 'statement_withdrawalToMN' : 'statement_withdrawalToTN'),
  [`${withdrawal_funds}/${lock_commission}/${output}`]: (payment) => window.$t(payment.currency === 'lion' ? getSystemUserCode(payment.recipientId) : 'statement_netFee'),
  [`${withdrawal_funds}/${transfer_commission_to_system}/${output}`]: (payment) => window.$t(payment.currency === 'lion' ? getSystemUserCode(payment.recipientId) : 'statement_netFee'),
  [`${deposit_funds}/${transfer_amount_to_user}/${input}`]: () => window.$t(isProdEnv ? 'statement_depositFromMN' : 'statement_depositFromTN'),
  [`${deposit_funds}/${transfer_commission_to_system}/${output}`]: (payment) => window.$t(payment.currency === 'lion' ? getSystemUserCode(payment.recipientId) : 'statement_netFee'),

  [`${transfer_fiat}/${transfer_amount_to_user}/${input}`]: () => window.$t('sysUser_deposit')
}
export const statementMapLocaleNames = {
  [`${autoexchange}/${lock_amount}/${output}`]: () => 'statement2_autoexchange_cr_amount',
  [`${autoexchange}/${lock_commission}/${output}`]: () => 'statement2_autoexchange_cr_comm',
  [`${autoexchange}/${transfer_amount_to_user}/${input}`]: () => 'statement2_autoexchange_res_amount',
  [`${autoexchange}/${unlock_commission_to_user}/${input}`]: () => 'statement2_autoexchange_comm_ret',
  [`${autoexchange}/${unlock_amount_to_user}/${input}`]: () => 'statement2_autoexchange_amount_ret',
  [`${autoexchange}/${transfer_commission_to_system}/${output}`]: (payment) => payment.currency === 'lion' ? getSystemUserCode(payment.recipientId) : 'statement_netFee',

  [`${exchange_with_both_commission}/${lock_amount}/${output}`]: () => 'statement2_exchange_cr_amount',
  [`${exchange_with_both_commission}/${lock_commission}/${output}`]: () => 'statement2_exchange_cr_comm',
  [`${exchange_with_both_commission}/${unlock_amount_to_user}/${input}`]: () => 'statement2_exchange_amount_ret',
  [`${exchange_with_both_commission}/${unlock_commission_to_user}/${input}`]: () => 'statement2_exchange_comm_ret',
  [`${exchange_with_both_commission}/${transfer_amount_to_user}/${input}`]: () => 'statement2_exchange_input_amount',

  [`${transfer_sender_commission}/${lock_amount}/${output}`]: () => 'statement2_transfer_cr_amount',
  [`${transfer_receiver_commission}/${lock_amount}/${output}`]: () => 'statement2_transfer_cr_amount',
  [`${transfer_sender_commission}/${lock_commission}/${output}`]: () => 'statement2_transfer_comm',
  [`${transfer_receiver_commission}/${lock_commission}/${output}`]: () => 'statement2_transfer_comm',
  [`${transfer_sender_commission}/${transfer_amount_to_user}/${input}`]: () => 'statement2_transfer_input',
  [`${transfer_receiver_commission}/${transfer_amount_to_user}/${input}`]: () => 'statement2_transfer_input',

  [`${withdrawal_funds}/${lock_amount}/${output}`]: () => isProdEnv ? 'statement_withdrawalToMN' : 'statement_withdrawalToTN',
  [`${withdrawal_funds}/${lock_commission}/${output}`]: (payment) => payment.currency === 'lion' ? getSystemUserCode(payment.recipientId) : 'statement_netFee',
  [`${withdrawal_funds}/${transfer_commission_to_system}/${output}`]: (payment) => payment.currency === 'lion' ? getSystemUserCode(payment.recipientId) : 'statement_netFee',
  [`${deposit_funds}/${transfer_amount_to_user}/${input}`]: () => isProdEnv ? 'statement_depositFromMN' : 'statement_depositFromTN',
  [`${deposit_funds}/${transfer_commission_to_system}/${output}`]: (payment) => payment.currency === 'lion' ? getSystemUserCode(payment.recipientId) : 'statement_netFee',

  [`${transfer_fiat}/${transfer_amount_to_user}/${input}`]: () => 'sysUser_deposit'
}
export function getStatementUserDescriptionV2 (payment, noAdditionalData = false) {
  const descKey = [payment.processType, payment.operationType, payment.type].join('/')
  let res
  let additionalData = {}
  try {
    if (!noAdditionalData) additionalData = payment
    const func = statementMapLocales[descKey]
    res = descKey
    if (func) res = func(additionalData)
  } catch (err) {
    console.log({err}, descKey, payment)
  }
  return res
}

export function getDescriptionLocaleKey (payment) {
  const recipientTypeCode = `${payment.recipientId}/${payment.processType}/${payment.type}`
  if (TRANSACTION_NAME_ALIASES[recipientTypeCode]) return TRANSACTION_NAME_ALIASES[recipientTypeCode]
  const isProdEnv = ['cryptoenter.com', 'cryptoenter.ru'].includes(window.location.host)
  if (isCommissionOperation(payment.operationType)) {
    const { processType } = payment
    if (processType.includes('EXCHANGE')) return  'statement_exchange_commission'
    return payment.currency === 'lion' ? getSystemUserCode(payment.recipientId) : 'statement_netFee'
  } else if (payment.processType === ProcessTypes.withdrawal_funds) {
    return isProdEnv ? 'statement_withdrawalToMN' : 'statement_withdrawalToTN'
  } else if (payment.processType === ProcessTypes.deposit_funds) {
    return isProdEnv ? 'statement_depositFromMN' : 'statement_depositFromTN'
  } else if (payment.processType === ProcessTypes.autoexchange) {
    return 'ae_autoexchange'
  } else {
    return undefined
  }
}

export function getCurrencyForLocale (payment) {
  const { lion, tether, ethereum } = CURRENCY_SHORT_CODES
  return payment.currencyShort === lion || payment.currencyShort === tether ? ethereum : payment.currencyShort
}

export default function (data) {
  const {
    amount: { amount, currency_type },
    input_output_type,
    timestamp,
    from_user_account_info,
    to_user_account_info,
    operation_id,
    operation_type,
    process_type,
    external_transaction_id,
    offer_operation_id,
    message
  } = data
  const recipient = input_output_type === 'INPUT' ? from_user_account_info : to_user_account_info
  this.timestamp = timestamp
  this.date = createDateOfTimeStamp(this.timestamp)
  this.time = createTimeWithSecondsOfTimeStamp(this.timestamp)
  this.currencyShort = CURRENCY_SHORT_CODES[currency_type] || currency_type
  this.type = input_output_type
  this.operationType = operation_type
  this.offerOperationId = offer_operation_id
  this.recipientId = recipient.user_id
  this.amount = new Decimal(amount).toFixed().toString()
  this.currency = currency_type
  this.operationId = operation_id
  this.processType = process_type
  this.externalTxId = external_transaction_id
  this.message = message

  this.currencyForLocale = getCurrencyForLocale(this)
  this.descriptionLocaleKey = getDescriptionLocaleKey(this)
  // this.description = getStatementUserDescription(this)
  this.description = getStatementUserDescriptionV2(this)
  this.filterValue = getStatementUserDescriptionV2(this, true)
  const operationDescKeyFunc = statementMapLocaleNames[[this.processType, this.operationType, this.type].join('/')]
  let operationDescKey = [this.processType, this.operationType, this.type].join('/')
  if (operationDescKeyFunc) operationDescKey = operationDescKeyFunc(this)
  this.filterValueLocaleCode = operationDescKey
}
