import { CURRENCY_SHORT_CODES, FIAT_CURRENCY_NAMES } from '@/constants/auto-exchange'
import { FORM_TYPE_NAMES } from '@/constants/P2P'
import moment from 'moment-mini'

export default function (data) {
  const {
    banknotes,
    city,
    country,
    exchange_rate,
    lock_txs_op_id,
    min_match_amount,
    number,
    operation_id,
    owned_value,
    requisites_hash,
    response_offer_op_id,
    status,
    timestamp,
    user_account_info,
    wanted_value
  } = data || {}
  const result = {
    banknotes,
    city,
    country,
    exchange_rate,
    lock_txs_op_id,
    min_match_amount,
    number,
    operation_id,
    owned_value,
    requisites_hash,
    response_offer_op_id,
    status,
    timestamp,
    user_account_info,
    wanted_value
  }
  result.isSelling = owned_value.type === FORM_TYPE_NAMES.INTERNAL
  if (result.isSelling) {
    result.first_amount = owned_value.amount
    result.first_currency = owned_value.currency
    result.second_amount = wanted_value.amount
    result.second_currency = wanted_value.currency
    result.asset = wanted_value.asset
    result.type = 'sell'
  } else {
    result.first_amount = wanted_value.amount
    result.first_currency = wanted_value.currency
    result.second_amount = owned_value.amount
    result.second_currency = owned_value.currency
    result.asset = owned_value.asset
    result.type = 'buy'
  }
  result.firstCurrencyShort = CURRENCY_SHORT_CODES[result.first_currency]
  result.secondCurrencyShort = CURRENCY_SHORT_CODES[result.second_currency]
  result.user_id = result.user_account_info?.user_id
  result._time = moment.unix(timestamp).format('DD/MM/YY HH:mm')
  result._rateCurrency = `${result.firstCurrencyShort}/${result.secondCurrencyShort}`
  result.isAssetOffer = [owned_value.type, wanted_value.type].includes(FORM_TYPE_NAMES.ASSET)
  return result
}
