import { DEAL_STATUS_LABEL, FORM_TYPE_NAMES } from '@/constants/P2P'
import { CURRENCY_SHORT_CODES } from '@/constants/auto-exchange'
import Decimal from 'decimal.js'
import moment from 'moment-mini'

export default class {
  constructor (data) {
    console.log({ data })
    Object.keys(data).forEach(prop => { this[prop] = data[prop] })
    this.isSelling = this.wanted_value.type === FORM_TYPE_NAMES.INTERNAL
    this.internalObj = this[this.isSelling ? 'wanted_value' : 'owned_value']
    this.externalObj = this[this.isSelling ? 'owned_value' : 'wanted_value']
    this.internalAmount = this.internalObj.amount
    this.internalCurrency = CURRENCY_SHORT_CODES[this.internalObj.currency]
    this.externalAmount = this.externalObj.amount
    this.externalCurrency = CURRENCY_SHORT_CODES[this.externalObj.currency]
    this.operationWithExternalFiat = [this.externalObj.type, this.internalObj.type].includes(FORM_TYPE_NAMES.FIAT)
    this.fiatTypes = this.externalObj.fiat_types
    this.userId = this.user_account_info.user_id
    this.statusText = DEAL_STATUS_LABEL[data.deal?.status] || ''
    if (this.deal) {
      this.dealOwnedAmount = this.isSelling ? this.getExternalDealAmount(this.deal.output_amount || 0) : this.deal.output_amount
      this.dealWantedAmount = !this.isSelling ? this.getExternalDealAmount(this.deal.output_amount || 0) : this.deal.output_amount
    }
    this._time = moment.unix(data.timestamp).format('DD/MM/YY HH:mm')
  }

  getExternalDealAmount (dealInternalAmount, decimalPlaces = 2) {
    if (!this.operationWithExternalFiat) return ''
    return new Decimal(this.internalAmount).mul(this.exchange_rate).toFixed(decimalPlaces)
  }
}
