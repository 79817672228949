export default function (data = {}) {
  const {
    amount,
    organization_id
  } = data
  return {
    amount,
    organization_id: organization_id || 'CEDevelop'
  }
}
