export default function (data = {}) {
  const {
    percent = '',
    value_type = ''
  } = data
  return {
    percent,
    value_type
  }
}
