export default function (data = {}) {
  const {
    amount,
    currency_type
  } = data
  return {
    amount: amount || '',
    currency_type: currency_type || ''
  }
}
