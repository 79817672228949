export default function (data = {}  ) {
  const {
    process_type,
    commission_percent,
    min,
    max,
  } = data
  return {
    process_type: process_type || '',
    commission_percent: commission_percent || '',
    min: min || '',
    max: max || ''
  }
}
