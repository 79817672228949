export default {
  state: {
    N: '',
    g: '',
    k: ''
  },
  getters: {
    getSRP6CryptoParams (state) {
      return state
    }
  },
  mutations: {
    updateSRP6CryptoParamsN (state, dataForUpdate) {
      state.N = dataForUpdate
    },
    updateSRP6CryptoParamsG (state, dataForUpdate) {
      state.g = dataForUpdate
    },
    updateSRP6CryptoParamsK (state, dataForUpdate) {
      state.k = dataForUpdate
    }
  }
}
