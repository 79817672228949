export default function (data = {}) {
  const {
    currency_type,
    max_value,
    min_value,
    scale,
    is_enabled,
    process_type,
    min_commission_amount,
    max_commission_amount,
    truncate_precision,
    organization_id
  } = data
  return {
    currency_type: currency_type || '',
    max_value: max_value || '',
    min_value: min_value || '',
    scale: scale || '',
    is_enabled: is_enabled ? 'true' : 'false',
    process_type: process_type || '',
    min_commission_amount: min_commission_amount || '',
    max_commission_amount: max_commission_amount || '',
    truncate_precision: truncate_precision || '',
    organization_id: organization_id || 'CEDevelop'
  }
}
