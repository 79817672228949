import keys from '@/i18n/keys'

function SystemUser (id, code = '') {
  this.id = id
  this.code = code
}

export let SystemUsers = {
  root: new SystemUser('a00010000001'),
  begin: new SystemUser('a00020000001', keys.sysUser_deposit),
  end: new SystemUser('a00030000001', keys.sysUser_withdrawal),
  lock: new SystemUser('a00040000001', keys.sysUser_lock),
  lock_commission: new SystemUser('a00050000001', keys.sysUser_lockComm),
  system_commission: new SystemUser('a00060000001', keys.sysUser_sysComm),
  lion: new SystemUser('a08880000001', '')
}

let sysUsersByIds = {}
for (let k in SystemUsers) {
  sysUsersByIds[SystemUsers[k].id] = SystemUsers[k]
}

export function isSystemUser (userId) {
  return Boolean(sysUsersByIds[userId])
}

export function getSystemUserCode (userId) {
  return sysUsersByIds[userId]?.code
}
