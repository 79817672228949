export const ProcessTypes = {
  move: 'MOVE',
  exchange_with_both_commission: 'EXCHANGE_WITH_BOTH_COMMISSION',
  transfer_sender_commission: 'TRANSFER_SENDER_COMMISSION',
  transfer_receiver_commission: 'TRANSFER_RECEIVER_COMMISSION',
  deposit_funds: 'DEPOSIT_FUNDS',
  withdrawal_funds: 'WITHDRAWAL_FUNDS',
  referral_revenue: 'REFERRAL_REVENUE',
  autoexchange: 'AUTOEXCHANGE',
  p2p: 'P2P_EXCHANGE',
  transfer_fiat: 'TRANSFER_FIAT_NO_COMMISSION'
}

export function isCommissionFromSender (processType) {
  if (!processType) return undefined
  return {
    [ProcessTypes.move]: true,
    [ProcessTypes.exchange_with_both_commission]: true,
    [ProcessTypes.transfer_sender_commission]: true,
    [ProcessTypes.transfer_receiver_commission]: false,
    [ProcessTypes.deposit_funds]: undefined,
    [ProcessTypes.withdrawal_funds]: true,
    [ProcessTypes.referral_revenue]: undefined,
    [ProcessTypes.autoexchange]: true
  }[processType]
}
