export default function (data = {}) {
  const {
    currencies_order,
    step,
    first_currency,
    second_currency,
    users_can_sell,
    users_can_buy
  } = data
  return {
    currencies_order: currencies_order || true,
    step: step || '',
    first_currency: first_currency || '',
    second_currency: second_currency || '',
    users_can_sell: users_can_sell || null,
    users_can_buy: users_can_buy || null,
  }
}
