export default function (data = {}) {
  const {
    company_name,
    country,
    legal_address,
    number,
    organization_id,
    type: orgType,
    website
  } = data
  return {
    company_name: company_name || '',
    country: country || '',
    legal_address: legal_address || '',
    number: number || '',
    organization_id: organization_id || '',
    type: orgType || '',
    website: website || '',
  }
}
