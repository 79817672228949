export default function (data = {}) {
  const {
    min_eth_amount,
    organization_id
  } = data
  return {
    min_eth_amount: min_eth_amount || '',
    organization_id: organization_id || ''
  }
}
