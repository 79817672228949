export default function (data = {}) {
  const {
    kyc_status,
    user_id
  } = data
  return {
    kyc_status: kyc_status || '',
    user_id: user_id || ''
  }
}
